import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import gifziplusion from "../Assets/gifziplusion.svg";
import './MyCard.css';

function MyCard({ limit = 6, showViewAll = false }) {
  const [cards, setCards] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCards = async () => {
      const baseUrl = `${process.env.REACT_APP_BASE_API_URL}gifzi_plus/all`;
      const token = localStorage.getItem("authToken");

      try {
        const response = await fetch(baseUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
        });

        if (response.ok) {
          const data = await response.json();
          if (data && data.data && data.data.cards) {
            setCards(data.data.cards);
          } else {
            setErrorMessage('No cards found.');
          }
        } else {
          setErrorMessage('Failed to fetch cards.');
        }
      } catch (error) {
        setErrorMessage('Error fetching cards. Please try again later.');
      }
    };

    fetchCards();
  }, []);

  // Only render this section if there are cards available
  if (cards.length === 0) {
    return null; // Hide the entire section if no cards are available
  }

  return (
    <div className='card-list'>
      <div className='container p-60'>
        <h3 className="title">Gifzi Plus Card</h3>
        <div className='row'>
          {errorMessage && (
            <div className="col-md-12">
              <p className='text-danger'>{errorMessage}</p>
            </div>
          )}

          {cards.slice(0, limit).map((card, index) => (
            <div key={index} className='col-md-3'>
              <div className='plus-item card p-3'>
                <i className="mr-3 prtimg">
                  <img
                    src={gifziplusion}
                    alt="product"
                    height="100%"
                    width="100%"
                  />
                </i>
                <p className='account-number f-20 mb-1 f-weight-600'>
                  {card.card_number}
                </p>
                <p className='text-danger f-16 mb-3 f-weight-600'>
                  Expiry: {card.expiry}
                </p>
                <input type="hidden" value={card.id} />

                <p className='text-main f-16 text-decoration-underline cursor-pointer' onClick={() => {
                  navigate('/balance', { 
                    state: { 
                      cardNumber: card.card_number, 
                      cardId: card.id 
                    } 
                  });
                }}>
                  Check Balance
                </p>
              </div>
            </div>
          ))}

        </div>
        
        {/* Show View All button if there are more than 4 cards */}
        {showViewAll && cards.length > limit && (
          <div className="text-center mt-4">
            <button className="common-btn" onClick={() => navigate('/mycard')}>
              View All
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default MyCard;
