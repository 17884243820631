import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // For redirection
import Commonbtn from '../../Commonbtn/Commonbtn';
import './Form.css';
import Input from '../../Input/Input';
import circle from '../../Assets/Contact/circle.svg';
import AlertRed from '../../Alert/AlertRed'; // Import Alert component
import Alert from '../../Alert/Alert'; // Import the updated Alert component for success
import { faEnvelope, faLocationPin, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faXTwitter, faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons';
import axios from 'axios';


function Form() {
    const form = useRef();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });
    const [errors, setErrors] = useState({});
    const [alert, setAlert] = useState(null); // State to handle alerts

    useEffect(() => {
        if (alert) {
            const timer = setTimeout(() => {
                setAlert(null);
            }, 3000); // Alert disappears after 3 seconds   

            return () => clearTimeout(timer); // Cleanup the timer
        }
    }, [alert]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.email) newErrors.email = 'Email is required';
        if (!formData.phone) newErrors.phone = 'Phone number is required';
        if (!formData.message) newErrors.message = 'Message is required';
        return newErrors;
    };

    const sendContactData = async (e) => {
        e.preventDefault();

        const formErrors = validateForm();
        setErrors(formErrors);
        
        // If there are errors, do not proceed
        if (Object.keys(formErrors).length > 0) return;

        // Check if user is logged in
        const token = localStorage.getItem('authToken');
        if (!token) {
            setAlert({ title: 'Please login first to send a message.' }); // Alert for login
            navigate('/login'); // Redirect to login page
            return;
        }

        // Create the payload as per the specified structure
        const payload = {
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            message: formData.message,
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}menu/sendMessage`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token, // Add auth token
                },
                body: JSON.stringify(payload), // Use the payload here
            });

            const result = await response.json();
            if (response.ok) {
                setAlert({ title: 'Message sent successfully!', type: 'success' });
                form.current.reset();
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                });
                setErrors({});
            } else {
                setAlert({ title: result.message || 'Failed to send message. Try again!', type: 'error' });
                console.error('Failed to send message:', result.message);
            }
        } catch (error) {
            console.error('Error sending message:', error);
            setAlert({ title: 'Error sending message. Check console for details.', type: 'error' });
        }
    };
// CONTACT DETAIL 
const [contactInfo, setContactInfo] = useState(null);

useEffect(() => {
  // Fetch contact us data from the API
  axios.get( `${process.env.REACT_APP_BASE_API_URL}menu/contactUs`)
    .then(response => {
      if (response.data.status === 200) {
        setContactInfo(response.data.data.contact_us);
      }
    })
    .catch(error => {
      console.error('Error fetching contact us data:', error);
    });
}, []);

if (!contactInfo) {
  return <div>Loading...</div>;
}
// CONTACT DETAIL 
    return (
        <div className='contact-form bg-fb p-80'>
            <div className='container'>
                <div className='row'>
                <div className='col-md-6 left-card text-white position-relative'>
      {/* Contact Information Section */}
      <div>
        <h3 className='f-40 mb-3'>Let’s start with us</h3>
        <p className='f-20'>{contactInfo.company_name} Founded - 2022</p>
      </div>
      <ul className='address-detail'>
        <li className='f-18 d-flex'>
          <FontAwesomeIcon icon={faPhone} />
          <span className="ms-2">{contactInfo.phone}</span>
        </li>
        <li className='f-18 d-flex'>
          <FontAwesomeIcon icon={faEnvelope} />
          <span className="ms-2">{contactInfo.email}</span>
        </li>
        <li className='f-18 d-flex'>
          <FontAwesomeIcon icon={faLocationPin} />
          <span className="ms-2">{contactInfo.address}</span>
        </li>
      </ul>
      <ul className='d-flex social-icon-list'>
        {contactInfo.facebook && <li><a target="_blank" href={contactInfo.facebook} className='facebook social-icon'><FontAwesomeIcon icon={faFacebook} /></a></li>}
        {contactInfo.linkedin && <li><a target="_blank" href={contactInfo.linkedin} className='linkdin social-icon'><FontAwesomeIcon icon={faLinkedin} /></a></li>}
        {contactInfo.twitter && <li><a target="_blank" href={contactInfo.twitter} className='twitter social-icon'><FontAwesomeIcon icon={faXTwitter} /></a></li>}
        {contactInfo.instagram && <li><a target="_blank" href={contactInfo.instagram} className='insta social-icon'><FontAwesomeIcon icon={faInstagram} /></a></li>}
      </ul>
      
      <div className='circle-vectr position-absolute'>
        <img src={circle} alt="circle" height="100%" width="100%" className='circle' />
      </div>
    </div>
                    <div className='col-md-6 form bg-white'>
                        {alert && (alert.type === 'error' ? <AlertRed title={alert.title} /> : <Alert title={alert.title} type={alert.type} />)} {/* Render alert if exists */}
                        <form ref={form} onSubmit={sendContactData} id="survey-form" className='d-flex-center text-start'>
                            <div className="row">
                                <div className="col-md-12">
                                    <Input
                                        placeholdertext="Enter your name"
                                        labeltext="Name"
                                        fortext="name"
                                        typetext="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        error={errors.name}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <Input
                                        placeholdertext="Enter your Number"
                                        labeltext="Phone Number"
                                        fortext="Phone Number"
                                        typetext="text"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        maxLength={10} // Restrict input to 10 digits
                                        error={errors.phone}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <Input
                                        placeholdertext="Enter your mail"
                                        labeltext="Email"
                                        fortext="email"
                                        typetext="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        error={errors.email}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <Input
                                        placeholdertext="Enter your Message"
                                        labeltext="Message"
                                        fortext="Message"
                                        typetext="text"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        error={errors.message}
                                    />
                                </div>
                                <div className="col-md-12 d-flex-center btn-form">
                                    <Commonbtn Commonbtntext="Send Message" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Form;