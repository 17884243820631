import React, { useState , useEffect  } from 'react';
import Logo from '../../Logo/Logo';
import './../Form.css';
import './Otp.css';
import Commonbtn from '../../Commonbtn/Commonbtn';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import Alert from '../../Alert/Alert'; // Import the Alert component
import AlertRed from '../../Alert/AlertRed'; // Import the Alert component

function Otp() {
    const [otp, setOtp] = useState(Array(6).fill(''));
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [resendLoading, setResendLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [alertMessage, setAlertMessage] = useState(null); // State for alert message
    const [showAlert, setShowAlert] = useState(false); // State to control alert visibility
    const [isError, setIsError] = useState(false); // State to track if alert is an error
    // Get mobile number from registration form via location.state
    const mobile = location.state?.mobile || '+91 - 8888888888';
    const otp2 = location.state?.otp || '';
    const first_name = location.state?.first_name;
    useEffect(() => {
        if (showAlert) {
            const timer = setTimeout(() => {
                setShowAlert(false); // Hide alert after 3 seconds
            }, 3000);
            return () => clearTimeout(timer); // Clean up the timer on component unmount
        }
    }, [showAlert]);

    // Handle change for each OTP input field
    const handleChange = (element, index) => {
        if (/^[0-9]$/.test(element.value) || element.value === "") {
            const newOtp = [...otp];
            newOtp[index] = element.value;
            setOtp(newOtp);

            // Move to the next input field after entering a number
            if (element.value && element.nextSibling) {
                element.nextSibling.focus();
            }
        }
    };

    // Handle key down events, specifically for backspace
    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace') {
            const newOtp = [...otp];
            newOtp[index] = '';
            setOtp(newOtp);
            if (index > 0) {
                // Move focus to the previous input field
                const previousInput = document.getElementById(`otp-input-${index - 1}`);
                previousInput.focus();
            }
        }
    };

    // Handle OTP form submission
    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        setError(null);

        const otpCode = otp.join('');
        if (otpCode.length !== 6) {
            setError("Please enter a valid 6-digit OTP.");
            return;
        }

        setLoading(true);
        const baseUrl = `${process.env.REACT_APP_BASE_API_URL}user/register/verify`;

        try {
            const response = await fetch(baseUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    type: 'mobile',
                    mobile: mobile,
                    otp: otpCode,
                    first_name: first_name,
                }),
            });

            const result = await response.json();

            localStorage.setItem('firstName', result.data.first_name);
            if (response.ok) {
                navigate('/login');
            } else {
                setError(result.message || 'OTP verification failed');
            }
        } catch (err) {
            setError('Something went wrong, please try again.');
            setIsError(true); // Set isError to true for error alert
            setShowAlert(true);
        }
        setLoading(false);
    };

    // Handle Resend OTP (commented out)
    // Handle Resend OTP
    const handleResendOtp = async () => {
        setResendLoading(true);
        setError(null);
        const baseUrl = `${process.env.REACT_APP_BASE_API_URL}user/register/resendOTP`;

        try {
            const response = await fetch(baseUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ mobile, first_name }),
            });

            const result = await response.json();

            if (response.ok) {
                console.log("OTP resent successfully:", result.data.otp);
            } else {
                setError(result.message || 'Failed to resend OTP');
            }
        } catch (err) {
            setError('Something went wrong, please try again.');
        }
        setResendLoading(false);
    };


    return (
        <section className='authentication otp card'>
            <div className=''>
                <Logo className="logo" />
                <h1 className='f-40 mb-2'>OTP Verification</h1>
                {/* <p className="otp-text mb-2">Your OTP: {otp2}</p> */}
                <p className='f-18 mb-4 greyf6'>
                    We sent you a one-time OTP on this Mobile Number <strong>{mobile}</strong>
                </p>

                <label className='f-16 f-weight-600'>Enter your OTP</label>
                <div className='inputs-5 d-flex'>
                    {otp.map((digit, index) => (
                        <input
                            key={index}
                            id={`otp-input-${index}`} // Assign an ID for reference
                            type="tel"
                            maxLength="1"
                            className='form-control form-control-lg input-otp'
                            value={otp[index]}
                            onChange={e => handleChange(e.target, index)}
                            onFocus={e => e.target.select()}
                            onKeyDown={e => handleKeyDown(e, index)} // Handle backspace key
                        />
                    ))}
                </div>

                {error && <p className='error-message'>{error}</p>}

                <p className='f-18 text-center mb-4'>
                    Did not receive OTP?
                    {/* <strong className='text-decoration-underline cursor-pointer'>
                        Resend
                    </strong> */}
                    {/* Uncomment and implement resend functionality if needed */}
                    <strong className='text-decoration-underline' onClick={handleResendOtp}>
                        {resendLoading ? 'Resending...' : 'Resend OTP'}
                    </strong>
                </p>
                {/* Conditionally render Alert or AlertRed */}
                {showAlert && (isError ? <AlertRed title={alertMessage} /> : <Alert title={alertMessage} />)}
           
           
                <form onSubmit={handleVerifyOtp}>
                    <div className="col-md-12 d-flex-center btn-form">
                        <Commonbtn Commonbtntext={loading ? 'Verifying...' : 'Verify code'} disabled={loading} />
                    </div>
                </form>

                <p className='f-16 greyf6 text-center mt-3'>
                    Back to <Link to='/login'><strong>Login</strong></Link>
                </p>
            </div>
        </section>
    );
}

export default Otp;
