import React, { useState } from 'react';
import cardd from '../Assets/dhand.png';
import cardd1 from '../Assets/dhand1.png';
import Success from '../Success/Success';
import CryptoJS from "crypto-js"; // Import CryptoJS for hashing

function Denomination({
  onClose, // handleCloseModal from Banner
  selectedDenomination,
  handleDenominationSelect,
  customAmount,
  handleCustomAmountChange,
  onAddToCart,
}) {
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null); // To show success or error message
  const [alertClass, setAlertClass] = useState(''); // Class to manage alert styling
  const token = localStorage.getItem("authToken");
  const firstName = localStorage.getItem('firstName'); // Retrieve first name
  const lastName = localStorage.getItem('lastName'); // Retrieve first name
  const userEmail = localStorage.getItem('userEmail'); // Retrieve first name
  const userMobile = localStorage.getItem('userMobile'); // Retrieve first name
  const userId = localStorage.getItem('userId'); // Retrieve first name
  // Update the denomination selection and input field together
  const handleSelectDenomination = (denomination) => {
    handleDenominationSelect(denomination); // Update the selected denomination
    handleCustomAmountChange({ target: { value: denomination } }); // Update the custom amount in the input field
  };

  const handleOrderNow = async () => {
    setLoading(true);
    const key = `${process.env.REACT_APP_PAYU_KEY}`
    const salt = `${process.env.REACT_APP_PAYU_SALT}`

    const generateTxnId = () => {
      const randomString = Math.random().toString(36).substr(2, 8); // Generate a random string
      const timestamp = Date.now(); // Get current timestamp
      return `${randomString}${timestamp}`; // Concatenate random string with timestamp
    };
    
    
    const txnid = generateTxnId();
    //const txnid = 't6svtqtjRdl1se'; // Use a unique txnid for each transaction
    const amount = customAmount || selectedDenomination; // Get the total amount
    //const amount = 1; // Get the total amount
    const productInfo = 'gifzi_plus_'+userId; // Example product info
    const firstname = firstName;
    const email = 'test@gmail.com';
   
    // Optional User Defined Fields (udf1-udf10)
    const udf1 = ''; 
    const udf2 = ''; 
    const udf3 = ''; 
    const udf4 = ''; 
    const udf5 = ''; 
    const udf6 = ''; 
    const udf7 = ''; 
    const udf8 = ''; 
    const udf9 = ''; 
    const udf10 = '';

    // Generate the hash
    const hashString = `${key}|${txnid}|${amount}|${productInfo}|${firstname}|${email}|${udf1}|${udf2}|${udf3}|${udf4}|${udf5}|${udf6}|${udf7}|${udf8}|${udf9}|${udf10}|${salt}`;

    

    const hash = CryptoJS.SHA512(hashString).toString(CryptoJS.enc.Hex);

    // Create a form element dynamically
    const form = document.createElement('form');
    form.action = `${process.env.REACT_APP_PAYU_URL}`; // PayU payment URL
    form.method = 'POST';
    const paymentmode = `${process.env.REACT_APP_PAYU_PAYMENTMODE}` // TEst
    
    // Create and append input elements
    const fields = [
      { name: "key", value: key },
      { name: "txnid", value: txnid },
      { name: "productinfo", value: productInfo },
      { name: "amount", value: amount },
      { name: "email", value: email },
      { name: "firstname", value: firstname },
      { name: "lastname", value: lastName },
      { name: "paymentmode", value: paymentmode },
      { name: "surl", value: `${process.env.REACT_APP_PAYU_SUCCESS_URL}` }, // Use environment variable if needed
      { name: "furl", value: `${process.env.REACT_APP_PAYU_FAILURE_URL}` },
      { name: "phone", value: userMobile },
      { name: "hash", value: hash }
    ];

    fields.forEach(({ name, value }) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = name;
      input.value = value;
      form.appendChild(input);
    });

    // Append the form to the body and submit
    document.body.appendChild(form);
    form.submit();

    
  };

  return (
    <div className="popup">
      <div className="modal-overlay">
        <div className="modal-content">
          <span className="close-modal-btn shadow-sm" onClick={onClose}>×</span> {/* Close modal */}
          <i className="d-img">
            <img src={cardd} height="100%" width="100%" alt="Denomination Card" />
          </i>
          <i className="d-img left">
            <img src={cardd1} height="100%" width="100%" alt="Denomination Card" />
          </i>
          <h4 className="f-28 f-weight-600 mb-4">Select Denomination or <br /> Enter Custom Amount</h4>
          <div className="denominations-list d-flex-center gap-2 mt-2 flex-wrap mt-3 f-14">
            {["10", "100", "1000", "10000", "25000", "50000", "100000"].map((denomination) => (
              <button
                key={denomination}
                className={`denomination-btn ${customAmount === denomination ? 'active' : ''}`}
                onClick={() => handleSelectDenomination(denomination)} // Update input when denomination is selected
              >
                {denomination}
              </button>
            ))}
          </div>
          <div className="custom-amount-input mt-3">
            <input
              type="number"
              placeholder="Enter custom amount (10 - 100000)"
              value={customAmount} // Control the input field with customAmount
              onChange={handleCustomAmountChange} // Update the amount when typing
              min="10"
              max="100000"
            />
          </div>

          {/* Display Success or Error Alert */}
          {alertMessage && (
            <div className={`alert ${alertClass}`}>{alertMessage}</div>
          )}

          <div className="mt-5">
            {/* Success button */}
            <Success onClick={handleOrderNow} buttonText={loading ? 'Processing...' : 'Order Now'} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Denomination;
