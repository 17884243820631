import React, { Component } from "react";
import './Captcha.css';
import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";

class CaptchaTest extends Component {
  state = {
    isCaptchaValid: false,
  };

  componentDidMount() {
    loadCaptchaEnginge(6); // Load the captcha engine with 6 characters
  }

  doSubmit = () => {
    const user_captcha = document.getElementById("user_captcha_input").value;

    if (validateCaptcha(user_captcha) === true) {
      this.props.onCaptchaVerified(true); // Notify parent about CAPTCHA validity
      this.setState({ isCaptchaValid: true }); // Set state to indicate CAPTCHA is valid
      loadCaptchaEnginge(6); // Reload the captcha
      document.getElementById("user_captcha_input").value = ""; // Clear input
    } else {
      this.props.onCaptchaVerified(false); // Notify parent about CAPTCHA invalidity
      alert("Captcha Does Not Match");
      document.getElementById("user_captcha_input").value = ""; // Clear input
    }
  };

  loadCaptchaAgain = () => {
    loadCaptchaEnginge(6); // Reload the captcha
  };

  render() {
    const { isCaptchaValid } = this.state;

    return (
      <div className="container mt-3">
        <div className="form-group">
          <div className="row border mx-0 align-items-center justify-content-between captcha-wth">
            <div className="col-md-8 mt-3">
              <LoadCanvasTemplateNoReload
                reloadColor="red"
                reloadText="reload"
              />
            </div>
            <div
              className="col-3 align-items-center btn btn-lg"
              onClick={this.loadCaptchaAgain}
            >
              &#x21bb;
            </div>

            {!isCaptchaValid && (
              <>
                <div className="col-md-12 mt-3 ml-0 pl-0">
                  <input
                    placeholder="Enter Captcha Value"
                    id="user_captcha_input"
                    name="user_captcha_input"
                    type="text"
                  />
                </div>
                <div className="col-md-3 mt-3 ml-0 pl-0">
                  <button className="btn btn-primary" onClick={this.doSubmit}>
                    Submit
                  </button>
                </div>
              </>
            )}

            {isCaptchaValid && (
              <div className="col-md-12 mt-3">
                <p className="text-success">CAPTCHA Verified!</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default CaptchaTest;
