import { faBell } from '@fortawesome/free-regular-svg-icons';
import './List.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

function List() {
  const notificationData = [
    {
      title: 'New Product Added',
      content: 'A new product has been added to our store. The product is called "Product X".',
      time: '5 hours ago'
    },
    {
      title: 'New Product Added',
      content: 'A new product has been added to our store. The product is called "Product X".',
      time: '5 hours ago'
    },
    {
      title: 'New Product Added',
      content: 'A new product has been added to our store. The product is called "Product X".',
      time: '5 hours ago'
    }
  ];

  const [clickedNotifications, setClickedNotifications] = useState([]);

  const handleNotificationClick = (index) => {
    setClickedNotifications((prevClicked) => [...prevClicked, index]);
  };

  return (
    <div className='notification p-60 bg-fb'>
      <div className='container notification-list'>
        <h2 className='text-center f-40'>Notification</h2>
        <div className='notification-list-main'>
          <h3 className='f-20 text-end'>Aug, 2024</h3>
          <div>
            {notificationData.map((item, index) => {
              const isActive = !clickedNotifications.includes(index);
              return (
                <div
                  key={index}
                  className={`notification-item row ${isActive ? 'active' : ''}`}
                  onClick={() => handleNotificationClick(index)}
                >
                  <div className='notification-icon col-md-1'>
                    <FontAwesomeIcon icon={faBell} className='text-white f-16' />
                  </div>
                  <div className='notification-content col-md-11'>
                    <h4 className='f-18 f-weight-600'>{item.title}</h4>
                    <p className='f-15 grey'>{item.content}</p>
                    <p className='text-muted f-14'>{item.time}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* Repeat of the notification list, you can DRY this up by extracting it into a function */}
        <div className='notification-list-main'>
          <h3 className='f-20 text-end'>Aug, 2024</h3>
          <div>
            {notificationData.map((item, index) => {
              const isActive = !clickedNotifications.includes(index);
              return (
                <div
                  key={index + notificationData.length} // Ensure unique keys for the repeated section
                  className={`notification-item row ${isActive ? 'active' : ''}`}
                  onClick={() => handleNotificationClick(index + notificationData.length)}
                >
                  <div className='notification-icon col-md-1'>
                    <FontAwesomeIcon icon={faBell} className='text-white f-16' />
                  </div>
                  <div className='notification-content col-md-11'>
                    <h4 className='f-18 f-weight-600'>{item.title}</h4>
                    <p className='f-15 grey'>{item.content}</p>
                    <p className='text-muted f-14'>{item.time}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default List;
