import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import westside from '../Assets/westside.png'; // Default image if not available in the response
import Commonbtn from '../Commonbtn/Commonbtn';
import './Myorder.css';

function MyOrderDetail() {
  const { id } = useParams(); // Access the order ID from the URL
  const [orderDetail, setOrderDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("authToken");

  const [selectedVoucherIndex, setSelectedVoucherIndex] = useState(0); 

  useEffect(() => {
    const fetchOrderDetail = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_API_URL}order/detail?id=${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        
        if (response.status === 200 && response.data?.data) {
          setOrderDetail(response.data.data);
          setError(null);
        } else {
          setError('Failed to fetch order details.');
        }
        setLoading(false);
      } catch (err) {
        setError('An error occurred while fetching order details. Please try again later.');
        setLoading(false);
      }
    };

    fetchOrderDetail();
  }, [id, token]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  // Extract the first voucher details if available
  const firstVoucher = orderDetail?.vouchers?.[0] || null;
  const voucherDetails = orderDetail?.vouchers;

  return (
    <div className='p-60 order-detail'>
      <div className='container'>
        <div className='Orderhistorydata-item shadow-sm rounded-3 row mb-3 align-items-center'>
          <div className='Orderhistorydata-icon col-md-3'>
            <img
              src={firstVoucher?.thumbnail || westside}
              alt="Order"
              height="100%"
              width="100%"
            />
          </div>
          <div className='Orderhistorydata-content col-md-9'>
            <div className='d-flex w-100 gap-2 align-items-center mb-1'>
            <h4 className='f-20 f-weight-600 text-muted '>
              Order id: <b>{orderDetail?.code}</b>
            </h4>
            
            <div className='d-flex btn-list  gap-2'>
              <button className={`status-status btn-lg ${orderDetail?.status === 1 ? 'success-status' : 'pending-status'}`}>
                {/* Status: {orderDetail?.status === 1 ? 'Success' : 'Pending'} */}
                Status: { 
                  orderDetail.status === 1 ? 'Success' : 
                  orderDetail.status === 2 ? 'Failed' : 
                            'Pending' 
                          }
              </button>
            </div>
            </div>
            <p className='text-muted f-16 mt-2'>
              Order date & time: {orderDetail?.order_dt}
            </p>
            <hr />
            
          
              <div className='first-voucher-details'>
                
                <p className='f-16 text-grey mb-1'>
              Transaction ID: <b>{orderDetail?.online_payment_data?.transaction_id || 'N/A'}</b>
            </p>
            <p className='f-16 mb-2 text-grey f-weight-600'>
              Order Code: {orderDetail?.order_code || 'N/A'}
            </p>
                
              

              </div>
          
            
         <div className='d-flex gap-2 mt-1'>
         <p className='f-18 f-weight-600 mb-1'>
              Total Amount: Rs. {orderDetail?.total_amount || 'N/A'}
            </p>
            <p>|</p>
            <p className='f-18 f-weight-600 mb-2 text-success'>
              Payment Mode: {orderDetail?.payment_mode === 1 ? 'Online' : 'Other'}
            </p>
         </div>
          
        
         
          
            {/* <p className='f-16 mb-1'>
              Gifzi Plus Order: {orderDetail?.is_gifzi_plus_order ? 'Yes' : 'No'}
            </p> */}
            <br/>
          

          

          </div>


      
        

        </div>
        

        {/* {orderDetail?.vouchers?.length > 0 && (
        orderDetail.vouchers.map((voucher, index) => (
          voucher.tnc && voucher.tnc.content && (
            <div className='col-md-12' key={index}>
            <img
              src={voucher?.thumbnail || westside}
              alt="Order"
              
            />
            <h4 className='f-18 f-weight-600 mb-1 text-main'>{voucher.product_name}</h4>
                <div className='d-flex gap-1 mb-2'>
                  <p className='f-16 mb-1 text-muted f-weight-600'>SKU: {voucher.sku}</p>
                  <p className='f-17'>|</p>
                  <p className='f-16 text-danger f-weight-600'>Validity: {voucher.validity}</p>
                  <p className='f-17'>|</p>
                  <p className='f-16 mb-1 text-muted f-weight-600'>Amount: {voucher.amount}</p>
                </div>

                {voucher.activation_code && (
                  <p className='f-16 mb-1'>
                    Activation Code: {voucher.activation_code}
                  </p>
                )}

                {voucher.activation_url && (
                  <p className='f-16 mb-1'>
                    Activation Url : {voucher.activation_url}
                  </p>
                )}
<b className='f-16 text-main mb-2 f-weight-600'>Card Detail</b>
                {voucher.card_number && (
                  <p className='f-16 mb-1'>
                    Card Number: {voucher.card_number}
                  </p>
                )}

                {voucher.card_pin && (
                  <p className='f-16 mb-1'>
                    Card Pin: {voucher.card_pin}
                  </p>
                )}

              {voucher.tnc.link && (
                <p className='f-16 mb-1'>
                  Please read TnC <a target="_blank" rel="noopener noreferrer" href={voucher.tnc.link}>from here</a>
                </p>
              )}
              <h2 className='f-20 text-main mb-2'><b>Terms & Conditions</b></h2>
              <div className='f-16 mb-1'>
                <div dangerouslySetInnerHTML={{ __html: voucher.tnc.content }} />
              </div>
            </div>
          )
        ))
      )} */}


        {/* Tabs for Vouchers */}
        <ul className="nav nav-tabs mb-3">
          {orderDetail?.vouchers?.map((voucher, index) => (
            <li className="nav-item" key={index}>
              <button
                className={`nav-link  ${selectedVoucherIndex === index ? 'active' : ''}`}
                onClick={() => setSelectedVoucherIndex(index)}
              >
                Voucher {index + 1}
              </button>
            </li>
          ))}
        </ul>

        {/* Display Selected Voucher Details */}
        {orderDetail?.vouchers?.[selectedVoucherIndex] && (
          <div className='col-md-12'>
            <i className='order-img-icon'>
            <img
              src={orderDetail?.vouchers?.[selectedVoucherIndex]?.thumbnail || westside}
              alt="Order"
              width="100%"
              height="100%"
            />
            </i>
        
            <h4 className='f-18 f-weight-600 mb-1 text-main'>{orderDetail.vouchers[selectedVoucherIndex].product_name}</h4>
            <div className='d-flex gap-1 mb-2'>
              <p className='f-16 mb-1 text-muted f-weight-600'>SKU: {orderDetail.vouchers[selectedVoucherIndex].sku}</p>
              <p className='f-17'>|</p>
              <p className='f-16 text-danger f-weight-600'>Validity: {orderDetail.vouchers[selectedVoucherIndex].validity}</p>
              <p className='f-17'>|</p>
              <p className='f-16 mb-1 text-muted f-weight-600'>Amount: {orderDetail.vouchers[selectedVoucherIndex].amount}</p>
            </div>
            <p className='f-16 mb-1'>
              Card Number: { 
                orderDetail.vouchers[selectedVoucherIndex].card_number 
                  ? orderDetail.vouchers[selectedVoucherIndex].card_number 
                  : 'null' 
              }

            </p>
            <p className='f-16 mb-1'>
              Card Pin: {orderDetail.vouchers[selectedVoucherIndex].card_pin}
            </p>
            {orderDetail.vouchers[selectedVoucherIndex].tnc?.link && (
              <p className='f-16 mb-1'>
                Please read TnC <a target="_blank" rel="noopener noreferrer" href={orderDetail.vouchers[selectedVoucherIndex].tnc.link}>from here</a>
              </p>
            )}
            <h2 className='f-20 text-main mb-2'><b>Terms & Conditions</b></h2>
            <div className='f-16 mb-1'>
              <div dangerouslySetInnerHTML={{ __html: orderDetail.vouchers[selectedVoucherIndex].tnc?.content || '' }} />
            </div>
          </div>
        )}
      </div>

      

    </div>
  );
}

export default MyOrderDetail;
