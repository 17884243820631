import React, { useEffect, useState } from 'react';
import axios from 'axios';
import westside from '../Assets/westside.png'; // Default image if not available in the response
import Commonbtn from '../Commonbtn/Commonbtn';
import SearchBar from '../Searchbar/Searchbar';
import './Myorder.css';
import { Link } from 'react-router-dom';

function Myorder() {
  const [orderHistoryData, setOrderHistoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const token = localStorage.getItem("authToken");

  // Fetch order history with optional search query
  const fetchOrderHistory = async (query = '') => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}order/${query ? 'search' : 'all'}?offset=0&limit=20${query ? `&search=${query}` : ''}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token, // Ensure token is passed correctly
          },
        }
      );

      // Handle API response
      if (response.status === 200) {
        const orders = response.data.data?.orders;

        // Check if orders exist
        if (orders && orders.length > 0) {
          setOrderHistoryData(orders); // Set order data
          setError(null); // Clear any error
        } else {
          setOrderHistoryData([]); // No orders found
          setError(null); // Clear any error
        }
      } else {
        // Handle non-200 responses
        setError('Failed to fetch orders.');
      }
      setLoading(false);
    } catch (err) {
      // Set a user-friendly error message
      setError('An error occurred while fetching orders. Please try again later.');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      fetchOrderHistory(); // Fetch all orders by default
    } else {
      setError('No authorization token found.');
      setLoading(false);
    }
  }, [token]);

  // Handle search query change
  const handleSearch = (query) => {
    setSearchQuery(query);
    fetchOrderHistory(query); // Trigger search when the user types
  };

  // Display loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Display error message if there was an error
  // Uncomment if you want to show the error message to users
  if (error) {
    return (
      <div className='Orderhistorydata p-60 bg-fb'>
      <div className='container Orderhistorydata-list'>
        <h2 className='f-40'>Order History</h2>

        <div className='row btn-list-order align-items-center'>
          <div className='col-md-12'>
            <SearchBar
              searchtext="Search Here By Order Id"
              searchtype="text"
              searchclass="shadow-sm mt-3 mb-3"
              onSearch={handleSearch} // Pass handleSearch as the onSearch prop
            />
          </div>
        </div>
      <div className='text-danger text-center p-60'>
        <h4 className='mt-5 mb-5 pb-3'> No Order available </h4>
      </div>
      </div>
      </div>
    );
  }

  return (
    <div className='Orderhistorydata p-60 bg-fb'>
      <div className='container Orderhistorydata-list'>
        <h2 className='f-40'>Order History</h2>

        <div className='row btn-list-order align-items-center'>
          <div className='col-md-12'>
            <SearchBar
              searchtext="Search Here By Order Id"
              searchtype="text"
              searchclass="shadow-sm mt-3 mb-3"
              onSearch={handleSearch} // Pass handleSearch as the onSearch prop
            />
          </div>
        </div>

        <div className='Orderhistorydata-list-main'>
          {/* Check if there are any orders */}
          {orderHistoryData.length > 0 ? (
            orderHistoryData.map((item, index) => (
              <Link to={`/Orderdetail/${item.id}`}>
             
              <div key={index} className='Orderhistorydata-item shadow-sm rounded-3 row mb-3'>
                <div className='Orderhistorydata-icon col-md-2'>
                  <img src={westside} className='text-white f-16' alt="Order" />
                </div>
                <div className='Orderhistorydata-content col-md-6'>
                  <h4 className='f-20 f-weight-600 text-main mb-1'>
                    Order id : <b className='text-main'>{item.code}</b>
                  </h4>
                  {/* <p className='f-18 f-weight-600 mb-1'>
                    Order total : Rs. {item.amount || 'N/A'}
                  </p> */}
                  <p className='f-18 f-weight-600 mb-2'>
                    Qty : <b>{item.qty || 1}</b>
                  </p>
                  <p className='text-muted f-16'>
                    Order date & time : {item.order_dt}
                  </p>
                </div>
                <div className='col-md-4 d-flex-center btn-list'>
                  {/* <Commonbtn Commonbtntext="Reorder" /> */}
                  <button className={`status-status btn-lg ${item.status === 1 ? 'success-status' : 'pending-status'}`}>
                  Status: { 
                            item.status === 1 ? 'Success' : 
                            item.status === 2 ? 'Failed' : 
                            'Pending' 
                          }
                  </button>
                </div>
              </div>
              </Link>
            ))
          ) : (
            // Display message when no orders are found
            <div className='text-muted text-center mt-3'>
              No orders found
              <div className='text-muted mt-3'>Try changing your search criteria or checking back later.</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Myorder;
