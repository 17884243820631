import React, { useContext } from 'react';
import "./Product.css";
import SearchBar from "../../Searchbar/Searchbar";
import Ctaegorylist from "../Ctaegorylist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { useInView } from "react-intersection-observer";
import { Link, useNavigate } from "react-router-dom";



function Product() {
  const navigate = useNavigate();
  const [products, setProducts] = React.useState([]);
  const [offset, setOffset] = React.useState(0);
  const [hasMore, setHasMore] = React.useState(true);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [isEmpty, setIsEmpty] = React.useState(false);
  

  const limit = 4; // Set limit to 4 products per page
  const { ref, inView } = useInView({
    threshold: 1,
    triggerOnce: false,
  });

  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`; // Define the base URL

  const fetchProductData = async (newOffset, query) => {
    try {
      const endpoint =
        query.trim() === ""
          ? `${baseUrl}product/all?offset=${newOffset}&limit=${limit}`
          : `${baseUrl}product/search?offset=${newOffset}&limit=${limit}&search=${encodeURIComponent(
              query
            )}`;

      console.log("endpoint", endpoint);

      const response = await fetch(endpoint, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const jsonData = await response.json();

      if (jsonData && jsonData.status === 200) {
        const productJson = jsonData.data?.products || [];
        if (productJson.length === 0 && newOffset === 0) {
          setIsEmpty(true);
          setHasMore(false);
        } else {
          setProducts((prevProducts) => [...prevProducts, ...productJson]);
          if (productJson.length < limit) {
            setHasMore(false);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    if (searchQuery.trim() !== "") {
      setOffset(0); 
      setProducts([]); 
      fetchProductData(0, searchQuery); 
    }
  }, [searchQuery]);

  React.useEffect(() => {
    if (inView && hasMore && searchQuery.trim() === "") {
      fetchProductData(offset, searchQuery);
      setOffset((prevOffset) => prevOffset + 4); 
    }
  }, [inView, hasMore, offset, searchQuery]);

  const handleSearch = (query) => {
    setSearchQuery(query);
    setProducts([]);
    setOffset(0);
    setHasMore(true);
    setIsEmpty(false);
  };

  const addToCart = (item) => {
    const storedItems = localStorage.getItem("cartItems");
    const currentItems = storedItems ? JSON.parse(storedItems) : [];
    const updatedItems = [...currentItems, item];
    localStorage.setItem("cartItems", JSON.stringify(updatedItems));
  };

  return (
    <div className="product-list p-60 text-center bg-fb">
      <div className="container">
        <Ctaegorylist />
        <h3 className="title">Gift Cards and Gift Vouchers</h3>
        <div className="row">
          <div className="col-md-12 mb-4">
            <SearchBar
              searchtext="Search Here"
              searchtype="text"
              onSearch={handleSearch}
            />
          </div>
          {/* <div className="col-md-2 filter-mob">
            <button className="common-btn d-flex">
              <FontAwesomeIcon icon={faFilter} className="text-white" />
              Filter
            </button>
          </div> */}
          {isEmpty ? (
            <div className="no-data">No data available</div>
          ) : (
            <>
              {products.map((item) => (
                <div
                  key={item.sku} // Use SKU as key for better performance
                  className="col-md-6 col-lg-3 col-sm-12 gift-card d-flex"
                  data-aos="fade-right"
                  data-aos-delay="300"
                  // onClick={() => handleProductClick(item.sku)} // Handle click on card
                >
                  <Link to={`/productdetail/${item.sku}`}>
                    <div className="card d-block border-0">
                      {/* <h3 className="product-name">{item.categoryname}</h3> */}
                      <h3 className="product-name">{item?.name}</h3>
                      <i className="product-image">
                        <img
                          className="rounded"
                          height="100%"
                          width="100%"
                          src={
                            item.thumbnail ||
                            "https://www.crafin.in/wp-content/uploads/2019/03/amazon.png"
                          } 
                          alt="product"
                        />
                      </i>
                    </div>
                  </Link>
                </div>
              ))}
              {hasMore && (
                <div ref={ref} style={{ height: "100px" }}>
                  <p>Loading more products...</p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Product;
