import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import './Product.css';
import SearchBar from '../../Searchbar/Searchbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

function Product() {
    const [products, setProducts] = React.useState([]);
    const [offset, setOffset] = React.useState(0);
    const [hasMore, setHasMore] = React.useState(true);
    const [searchQuery, setSearchQuery] = React.useState("");
    const [isEmpty, setIsEmpty] = React.useState(false);
    const limit = 4;

    const { ref, inView } = useInView({
        threshold: 1,
        triggerOnce: false,
    });

    const { id } = useParams(); // Get category ID from URL
    const location = useLocation(); // Get location to retrieve passed state
    const { categoryName } = location.state || { categoryName: "Products" }; // Default to "Products" if not passed

    const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;

    const fetchProductData = async (newOffset, query) => {
        try {
            const endpoint = query.trim() === ""
                ? `${baseUrl}product/category?category_id=${id}&offset=${newOffset}&limit=${limit}`
                : `${baseUrl}product/search?offset=${newOffset}&limit=${limit}&search=${encodeURIComponent(query)}`;

            const response = await fetch(endpoint, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            });

            const jsonData = await response.json();

            if (jsonData && jsonData.status === 200) {
                const productJson = jsonData.data?.products || [];
                if (productJson.length === 0 && newOffset === 0) {
                    setIsEmpty(true);
                    setHasMore(false);
                } else {
                    setProducts((prevProducts) => [...prevProducts, ...productJson]);
                    if (productJson.length < limit) {
                        setHasMore(false);
                    }
                }
            }
        } catch (err) {
            console.error("Error fetching products:", err);
        }
    };

    React.useEffect(() => {
        fetchProductData(0, searchQuery);
    }, [searchQuery, id]);

    React.useEffect(() => {
        if (inView && hasMore) {
            fetchProductData(offset, searchQuery);
            setOffset((prevOffset) => prevOffset + 1);
        }
    }, [inView, hasMore]);

    const handleSearch = (query) => {
        setSearchQuery(query);
        setProducts([]);
        setOffset(0);
        setHasMore(true);
        setIsEmpty(false);
    };

    return (
        <div className="product-list p-80 text-center bg-fb">
            <div className="container">
                <h3 className="title">{categoryName}</h3> {/* Display passed category name */}
                <div className="row">
                    <div className="col-md-10 mb-4">
                        <SearchBar searchtext="Search Here" searchtype="text" onSearch={handleSearch} />
                    </div>
                    <div className="col-md-2 filter-mob">
                        <button className="common-btn d-flex">
                            <FontAwesomeIcon icon={faFilter} className="text-white" />
                            Filter
                        </button>
                    </div>
                    {isEmpty ? (
                        <div className="no-data">No data available</div>
                    ) : (
                        <>
                            {products.map((item, index) => (
                                <div
                                    key={index}
                                    className="col-md-6 col-lg-3 col-sm-12 gift-card"
                                    data-aos="fade-right"
                                    data-aos-delay="300"
                                >
                                    <Link to={`/productdetail/${item.sku}`} className="card d-block border-0">
                                    <h3
                                           className="f-20 mb-2"
                                        >
                                            {item?.name}
                                        </h3>
                                        <i className="product-image">
                                            <img src={item.thumbnail} alt="product" height="100%" width="100%" />
                                        </i>
                                      
                                    </Link>
                                </div>
                            ))}
                            {hasMore && (
                                <div ref={ref} style={{ height: "100px" }}>
                                    <p>Loading more products...</p>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Product;
