import React, { createContext, useState, useEffect } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItemCountNav, setCartItemCount] = useState(0);

  // Function to update cart count
  const updateCartCount = (newCount) => {
    setCartItemCount(newCount);
  };

  // Fetch cart items and update count
  useEffect(() => {
    const fetchCartItems = async () => {
      const token = localStorage.getItem("authToken"); // Get token from local storage

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}cart/all`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (
          data &&
          data.status === 200 &&
          data.data &&
          Array.isArray(data.data.cartData)
        ) {
          // Update cart item count
          const totalItems = data.data.totalQuantity; // Assuming this comes from your API
          updateCartCount(totalItems); // Update context with new cart count
        } else {
          console.error("Failed to fetch cart items:", data.message);
        }
      } catch (error) {
        console.error("Error fetching cart items:", error);
      }
    };

    fetchCartItems();
  }, []); // Empty dependency array to run on mount

  return (
    <CartContext.Provider value={{ cartItemCountNav, updateCartCount }}>
      {children}
    </CartContext.Provider>
  );
};
