import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import AlertRed from "../Alert/AlertRed";
import Alert from "../Alert/Alert";
import { Link, useNavigate } from "react-router-dom";
import "./MyCart.css";
import card1 from "../Assets/Icon/card1.png";
import card2 from "../Assets/Icon/card2.png";
import card3 from "../Assets/Icon/card3.png";
import card4 from "../Assets/Icon/card4.png";
import Input from "../Input/Input";
import SomeoneElse from "./SomeoneElse";
import CryptoJS from "crypto-js"; // Import CryptoJS for hashing
import Select from "../Input/Select";
import axios from "axios";
import { CartContext } from "../../context/CartContext";

function MyCart() {
  const [totalItems, setTotalItems] = useState(0);
  // const [selectedCard, setSelectedCard] = useState("");
  //const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(""); // Holds the selected region
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pin, setPin] = useState(""); // Holds the entered PIN
  const [showAlert, setShowAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [selectedCard, setSelectedCard] = useState("gifziPlus");
  const [showConfirm, setShowConfirm] = useState(false);
  const [itemToRemove, setItemToRemove] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0); // Holds the total amount
  const [selectedCards, setSelectedCards] = useState([]); // To store all selected card numbers
  const [selectedCardNumber, setSelectedCardNumber] = useState("");
  const [refresh, setRefresh] = useState(false); // State to trigger useEffect
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  const firstName = localStorage.getItem("firstName"); // Retrieve first name
  const lastName = localStorage.getItem("lastName"); // Retrieve first name
  const userEmail = localStorage.getItem("userEmail"); // Retrieve first name
  const userMobile = localStorage.getItem("userMobile"); // Retrieve first name
  const userId = localStorage.getItem("userId"); // Retrieve first name
  const [alertMessage, setAlertMessage] = useState(""); // State for alert message
  const { cartItemCountNav, updateCartCount } = useContext(CartContext);

  console.log("userEmail", userEmail);
  const productInfo = "giftcard_" + userId; // Example product info

  const handleCardSelection = (card) => {
    setSelectedCard(card); // Update selected card state
    // Reset all states related to cart and card selection
    setCartItems([]); // Clear the cart items
    setSelectedCountry([]); // Clear the user cards
    setTotalAmount(calculateTotal());
    setSelectedCards([]); // Clear selected cards
    setSelectedRegion(""); // Clear selected region (card)
    setPin(""); // Reset the pin
    setRefresh((prev) => !prev); // Toggle refresh to re-trigger useEffect
  };

  useEffect(() => {
    const fetchCartItems = async () => {
      setLoading(true); // Set loading to true when fetching starts
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}cart/all`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: token, // Ensure token is prefixed with "Bearer "
            },
          }
        );

        // Check if the response is ok (status in the range 200-299)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (
          data &&
          data.status === 200 &&
          data.data &&
          Array.isArray(data.data.cartData)
        ) {
          setCartItems(data.data.cartData); // Directly set cart data
          setTotalItems(data.data.totalItems);
        } else {
          console.error("Failed to fetch cart items:", data.message);
        }
      } catch (error) {
        console.error("Error fetching cart items:", error);
      } finally {
        setLoading(false); // Set loading to false when fetching ends
      }
    };

    const fetchUserCardList = async () => {
      setLoading(true); // Set loading to true when fetching starts
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}gifzi_plus/all`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: token, // Ensure token is prefixed with "Bearer "
            },
          }
        );

        // Check if the response is ok (status in the range 200-299)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (
          data &&
          data.status === 200 &&
          data.data &&
          Array.isArray(data.data.cards)
        ) {
          setSelectedCountry(data.data.cards); // Ensure selectedCountry is set as an array
        } else {
          console.error("Failed to fetch cart items:", data.message);
        }
      } catch (error) {
        console.error("Error fetching cart items:", error);
      } finally {
        setLoading(false); // Set loading to false when fetching ends
      }
    };

    fetchCartItems();
    fetchUserCardList();
  }, [token, refresh]);

  // Calculate total cart amount when cart items change
  useEffect(() => {
    setTotalAmount(calculateTotal()); // Initialize total amount when cart items load
  }, [cartItems]);

  // Check card balance API call
  const checkCardBalance = async () => {
    try {
      const requestBody = {
        card_id: selectedRegion,
        card_pin: pin,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}gifzi_plus/balance`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();
      if (data.status === 200 && data.data) {
        const cardBalance = parseFloat(data.data.balance);

        let newTotal = totalAmount - cardBalance;
        if (newTotal < 0) newTotal = 0; // Ensure the total doesn't go below zero

        setTotalAmount(newTotal);

        setSelectedCountry((prevCountries) => {
          const updatedCountries = prevCountries.filter(
            (country) => country.id != selectedRegion
          );
          console.log("Updated Countries:", updatedCountries);
          return updatedCountries;
        });
        const selectedCard = selectedCountry.find(
          (region) => region.id == selectedRegion
        );

        if (selectedCard && !selectedCards.includes(selectedCard.card_number)) {
          setSelectedCards((prevCards) => [
            ...prevCards,
            selectedCard.card_number,
          ]);
          setSelectedCardNumber(selectedCard.card_number);
        }
        setSelectedRegion("");
        setPin("");

        console.log("New Cart Total:", newTotal);
      } else {
        console.error("Failed to check card balance:", data.message);
      }
    } catch (error) {
      console.error("Error checking card balance:", error);
    }
  };

  const handleQuantityChange = async (cartId, sku, type) => {
    setCartItems((prevItems) => {
      const updatedItems = prevItems.map((item) => {
        if (item.id === cartId) {
          let newQty = type === "increment" ? item.qty + 1 : item.qty - 1;
         if (type === "increment") {
            updateCartCount(cartItemCountNav + 1);
          } else {
            updateCartCount(cartItemCountNav - 1);
          }

          // Check for the max quantity limit
          if (newQty > 10) {
            setShowAlert(true);
            setAlertMessage("You cannot add more than 10 items!");
            setTimeout(() => setShowAlert(false), 2000); // Show alert for 2 seconds
            return item; 
          }

          newQty = newQty < 1 ? 1 : newQty;
          updateCartItemQuantity(cartId, sku, newQty, item.amount);
          return { ...item, qty: newQty }; 
        }
        return item;
      });

      const newTotalItems = updatedItems.reduce(
        (total, item) => total + item.qty,
        0
      );
      setTotalItems(newTotalItems);
      return updatedItems;
    });
  };

  const updateCartItemQuantity = async (cartId, sku, qty, productPrice) => {
    const requestBody = {
      cart_id: cartId,
      sku: sku,
      qty: qty,
      product_price: productPrice,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}cart/update`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();
      if (data.status !== 200) {
        console.error("Failed to update item quantity:", data.message);
      }
    } catch (error) {
      console.error("Error updating item quantity:", error);
    }
  };

  const handleRegionChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedRegion(selectedValue);
  };

  const handleRemoveItem = (cartId, qty) => {
    setItemToRemove(cartId);
    setItemToRemove({ id: cartId, qty: qty });
    setShowConfirm(true);
  };

  const handleConfirmRemove = async () => {
    try {
      const requestBody = {
        cart_id: itemToRemove.id,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}cart/remove`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(requestBody), // Send the request body
        }
      );

      const data = await response.json();
      if (data.status === 200) {
        updateCartCount((prevCount) => {
          const newCount = prevCount - itemToRemove.qty;
          return newCount;
        });

        setCartItems((prevItems) =>
          prevItems.filter((item) => item.id !== itemToRemove.id)
        );
        setItemToRemove({ id: null, qty: null });
        setShowSuccessAlert(true);
        setTimeout(() => setShowSuccessAlert(false), 2000); 
      } else {
        console.error("Failed to remove item:", data.message);
      }
    } catch (error) {
      console.error("Error removing item:", error);
    } finally {
      setShowConfirm(false);
    }
  };

  const handleCancelRemove = () => {
    setShowConfirm(false);
  };

  // Handle PIN input change
  const handlePinChange = (e) => {
    setPin(e.target.value);
  };

  const calculateTotal = () => {
    return cartItems.reduce(
      (total, item) => total + parseFloat(item.amount) * item.qty,
      0
    );
  };

  const handlePayment = async () => {
    const key = `${process.env.REACT_APP_PAYU_KEY}`;
    const salt = `${process.env.REACT_APP_PAYU_SALT}`;

    try {
      const paymentDetails = "";
      // Send POST request to the order checkout API
      //const response = await axios.post(`${process.env.REACT_APP_BASE_API_URL}/order/checkout`, paymentDetails);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}order/validate`, // Correct API endpoint
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`, // Ensure the token is prefixed with "Bearer "
          },
        }
      );

      // Handle success response
      console.log("Payment Success:", response.data);

      const generateTxnId = () => {
        const randomString = Math.random().toString(36).substr(2, 8); // Generate a random string
        const timestamp = Date.now(); // Get current timestamp
        return `${randomString}${timestamp}`; // Concatenate random string with timestamp
      };

      const txnid = generateTxnId();
      const amount = calculateTotal().toString(); // Get the total amount
      const firstname = firstName;
      const email = "test@gmail.com";

      // Optional User Defined Fields (udf1-udf10)
      const udf1 = "";
      const udf2 = "";
      const udf3 = "";
      const udf4 = "";
      const udf5 = "";
      const udf6 = "";
      const udf7 = "";
      const udf8 = "";
      const udf9 = "";
      const udf10 = "";

      // Generate the hash
      const hashString = `${key}|${txnid}|${amount}|${productInfo}|${firstname}|${email}|${udf1}|${udf2}|${udf3}|${udf4}|${udf5}|${udf6}|${udf7}|${udf8}|${udf9}|${udf10}|${salt}`;

      const hash = CryptoJS.SHA512(hashString).toString(CryptoJS.enc.Hex);

      // Create a form element dynamically
      const form = document.createElement("form");
      form.action = `${process.env.REACT_APP_PAYU_URL}`; // PayU payment URL
      form.method = "POST";
      const paymentmode = `${process.env.REACT_APP_PAYU_PAYMENTMODE}`; // TEst

      // Create and append input elements
      const fields = [
        { name: "key", value: key },
        { name: "txnid", value: txnid },
        { name: "productinfo", value: productInfo },
        { name: "amount", value: amount },
        { name: "email", value: email },
        { name: "firstname", value: firstname },
        { name: "lastname", value: lastName },
        { name: "paymentmode", value: paymentmode },
        { name: "surl", value: `${process.env.REACT_APP_PAYU_SUCCESS_URL}` }, // Use environment variable if needed
        { name: "furl", value: `${process.env.REACT_APP_PAYU_FAILURE_URL}` },
        { name: "phone", value: userMobile },
        { name: "hash", value: hash },
      ];

      fields.forEach(({ name, value }) => {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = name;
        input.value = value;
        form.appendChild(input);
      });

      // Append the form to the body and submit
      document.body.appendChild(form);
      form.submit();
    } catch (error) {
      setShowAlert(true);
      setAlertMessage(error.response.data.message);
      setTimeout(() => {
        setShowAlert(false); 
        navigate("/profile"); 
      }, 4000);

      console.error("Payment Error:", error.response.data);
    }
  };

  const handlePaymentDirect = async () => {
    const generateTxnId = () => {
      const randomString = Math.random().toString(36).substr(2, 8); // Generate a random string
      const timestamp = Date.now(); 
      return `${randomString}${timestamp}`; 
    };

    const txnid = generateTxnId();
    const paymentDetails = {
      payment_mode: 1,
      status: 1,
      is_gift: 0,
      online_payment_detail: {
        transaction_id: txnid,
        amount: calculateTotal().toString(), 
      },
    };
    try {
      // Send POST request to the order checkout API
      //const response = await axios.post(`${process.env.REACT_APP_BASE_API_URL}/order/checkout`, paymentDetails);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}order/checkout`,
        paymentDetails,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token, // Ensure the token is included with "Bearer "
          },
        }
      );

      console.log("Payment Success:", response.data);
    } catch (error) {
      setShowAlert(true);
      setAlertMessage(error.response.data.message);
      setTimeout(() => {
        setShowAlert(false); // Hide the alert
      }, 3000);
      console.error("Payment Error:", error.response.data);
    }
  };

  if (loading) {
    return <div>Loading cart items...</div>;
  }

  return (
    <div className="cart p-60">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="left">
              {/* <h1>Total Items in Cart: </h1> */}
              <h3 className="f-24">Cart</h3>

              <p className="f-18">
                You have{" "}
                <span className=" text-main f-11">{totalItems} item(s) </span>{" "}
                in your cart
              </p>

              {showAlert && <AlertRed title={alertMessage} />}
              {showSuccessAlert && (
                <Alert title="Item deleted successfully!" /> // Add your alert component here
              )}

              {showConfirm && (
                <>
                  <div className="overlay" /> {/* Add the overlay */}
                  <div className="confirmation-modal">
                    <h4>Are you sure you want to delete this item?</h4>
                    <div className="modal-actions d-flex-center gap-2">
                      <button
                        className="btn btn-danger"
                        onClick={handleConfirmRemove}
                      >
                        Yes
                      </button>
                      <button
                        className="btn btn-secondary"
                        onClick={handleCancelRemove}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </>
              )}
              <div className="cart-items-list mt-3">
                {cartItems.map((item, index) => (
                  <div
                    key={index}
                    className="cart-item-card d-flex align-items-center mb-3 gap-3 shadow-sm p-2 rounded-3"
                  >
                    <img
                      src={item.thumbnail}
                      alt={item.name}
                      height="100px"
                      width="150px"
                      className="cart-img"
                    />
                    <div className="cart-content col-lg-6 col-md-12 col-sm-12">
                      <p className="f-20">{item.name}</p>
                      <p className="f-16 f-weight-600 mt-1">
                        Price: Rs. {parseFloat(item.amount).toFixed(2)}
                      </p>
                    </div>
                    <div className="d-flex-center qty-main gap-2">
                      <div className="d-flex align-items-center qty">
                        <button
                          className="btn btn-secondary"
                          onClick={() =>
                            handleQuantityChange(item.id, item.sku, "decrement")
                          }
                        >
                          -
                        </button>
                        <span className="mx-2">{item.qty}</span>
                        <button
                          className="btn btn-secondary-plus"
                          onClick={() =>
                            handleQuantityChange(item.id, item.sku, "increment")
                          }
                        >
                          +
                        </button>
                      </div>
                      <div
                        className="remove"
                        onClick={() => handleRemoveItem(item.id, item.qty)}
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="text-danger cursor-pointer"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {cartItems.length > 0 && (
            <div className="col-lg-4 col-md-12 col-sm-12 payment-detail ">
              <div className="right">
                <div className="select-card mt-2">
                  <h3 className="f-18 text-white">Select Card</h3>
                  <div className="d-flex-center justify-content-start gap-2 mt-2 p-1 d-none">
                    <div
                      className={`card ${
                        selectedCard === "onlinepayment" ? "active" : ""
                      }`}
                      onClick={() => handleCardSelection("onlinepayment")}
                    >
                      <img src={card2} alt="Gift Card" />
                    </div>
                    <div
                      className={`card ${
                        selectedCard === "onlinegifziplus" ? "active" : ""
                      }`}
                      onClick={() => handleCardSelection("onlinegifziplus")}
                    >
                      <img src={card3} alt="Bonus Card" />
                    </div>
                    <div
                      className={`card p-1 text-center ${
                        selectedCard === "giftPlus" ? "active" : ""
                      }`}
                      onClick={() => handleCardSelection("giftPlus")}
                    >
                      <img src={card4} alt="Gift Plus Card" />
                    </div>
                  </div>
                </div>

                {/* Payment Card Section */}
                <div className="payment-card">
                  <div className="tab-navigation">
                    {/* Show Tabs only when a card is selected */}
                    {selectedCard && (
                      <>
                        {/* Conditionally render payment details based on selected card */}
                        <div className="tab-content mt-3">
                          <button
                            className="checkout text-white mb-3 w-100"
                            onClick={handlePayment}
                          >
                            Proceed to Payment
                          </button>
                          {selectedCard === "onlinegifziplus_1" && (
                            <div className="tab-pane fade show active">
                              {selectedCards.length > 0 && (
                                <ul className="apply-card">
                                  {selectedCards.map((cardNumber, index) => (
                                    <li key={index}>{cardNumber}</li>
                                  ))}
                                </ul>
                              )}
                              {/* Bonus card specific details */}
                              <div className="col-md-12 mt-3 mb-2">
                                <select
                                  name="country_id"
                                  className="form-control shadow-sm"
                                  value={selectedRegion}
                                  onChange={handleRegionChange}
                                >
                                  <option value="">Select a Card</option>
                                  {selectedCountry.map((region) => (
                                    <option key={region.id} value={region.id}>
                                      {region.card_number}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-md-12">
                                <input
                                  type="text"
                                  name="pin"
                                  placeholder="Enter Pin"
                                  className="form-control shadow-sm"
                                  value={pin}
                                  onChange={handlePinChange}
                                />
                              </div>
                              <div className="col-md-12 mb-2">
                                <button
                                  className="btn btn-primary"
                                  onClick={checkCardBalance}
                                >
                                  Apply Card
                                </button>
                              </div>
                            </div>
                          )}
                          {selectedCard === "giftPlus_1" && (
                            <div className="tab-pane fade show active">
                              {/* GiftPlus card specific details */}
                              {selectedCards.length > 0 && (
                                <ul className="apply-card">
                                  {selectedCards.map((cardNumber, index) => (
                                    <li key={index}>{cardNumber}</li>
                                  ))}
                                </ul>
                              )}
                              <div className="col-md-12 mt-3 mb-2">
                                <select
                                  name="country_id"
                                  className="form-control shadow-sm"
                                  value={selectedRegion}
                                  onChange={handleRegionChange}
                                >
                                  <option value="">Select a Card</option>
                                  {selectedCountry.map((region) => (
                                    <option key={region.id} value={region.id}>
                                      {region.card_number}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-md-12 mb-2">
                                <input
                                  type="text"
                                  name="pin"
                                  placeholder="Enter Pin"
                                  className="form-control shadow-sm"
                                  value={pin}
                                  onChange={handlePinChange}
                                />
                              </div>
                              <div className="col-md-12">
                                <button
                                  className="btn btn-primary"
                                  onClick={checkCardBalance}
                                >
                                  Apply Card
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="price">
                  <div className="col-md-12 d-flex justify-content-between mb-3 text-white f-16 mt-2">
                    <p>Total (Tax incl.)</p>
                    <p className=" f-18 f-weight-600">
                      {totalAmount.toFixed(2)}
                    </p>
                  </div>
                  {/* <div className="total-price mt-3">
                <h4>Total: Rs. {calculateTotal().toFixed(2)}</h4>
              </div> */}

                  <div className=" mt-3 paymnt">
                    {(selectedCard == "onlinegifziplus_1" ||
                      selectedCard == "onlinepayment_1") && (
                      <button
                        className="checkout text-white mb-3 w-100"
                        onClick={handlePayment}
                      >
                        Proceed to Payment
                      </button>
                    )}
                    <Link className="d-block" to="/gift-cards">
                      <button className="outline-white-btn w-100">
                        Back Shopping
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* <SomeoneElse /> */}
        </div>
      </div>
    </div>
  );
}

export default MyCart;
