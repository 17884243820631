import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Input from '../Input/Input';
import pluscard from '../Assets/Icon/pluscard.svg';
import './Balance.css';
import CaptchaTest from '../Captcha/captcha';
import Commonbtn from '../Commonbtn/Commonbtn';
import ResultValue from './ResultValue';

function Balance() {
  const location = useLocation();
  const [cardNumber, setCardNumber] = useState(location.state?.cardNumber || '');
  const [cardPin, setCardPin] = useState('');
  const [balanceDetails, setBalanceDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);

  const token = localStorage.getItem("authToken");
  const cardId = location.state?.cardId;

  const checkBalance = async () => {
    const baseUrl = `${process.env.REACT_APP_BASE_API_URL}gifzi_plus/balance`;

    try {
      const response = await fetch(baseUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: JSON.stringify({ 
          card_number: cardNumber,
          card_pin: cardPin,
          card_id: cardId 
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setBalanceDetails(data.data);
        setErrorMessage('');
      } else {
        setErrorMessage(data.message || 'Failed to fetch balance details');
      }
    } catch (error) {
      setErrorMessage('Error fetching balance details. Please try again later.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isCaptchaValid) {
      checkBalance();
    } else {
      setErrorMessage('Please complete the CAPTCHA first.');
    }
  };

  const handleCaptchaVerification = (isValid) => {
    setIsCaptchaValid(isValid);
  };

  return (
    <div className='balance p-60'>
      <div className='container'>
        <h2 className='f-40 f-weight-600 mb-4'>Check balance</h2>
        <div className='row bg-fb shadow-sm rounded-3'>
          <div className='col-md-12 pluscrad'>
            <img
              src={pluscard}
              alt='logo'
              data-aos='fade-right'
              data-aos-delay='500'
              height='100%'
              width='100%'
            />
          </div>

          <div className='col-md-12'>
            <h3 className='f-30 mb-30'>Enter card detail to check balance!</h3>
          </div>

          <div className='col-md-12'>
            <Input
              placeholdertext='Enter here card number'
              labeltext='Card Number'
              fortext='Card Number'
              typetext='text'
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
            />
          </div>
          <div className='col-md-12'>
            <Input
              placeholdertext='Enter here card pin'
              labeltext='Card Pin'
              fortext='pin'
              typetext='password'
              value={cardPin}
              onChange={(e) => setCardPin(e.target.value)}
            />
          </div>

          <div className='col-md-12'>
            <CaptchaTest onCaptchaVerified={handleCaptchaVerification} />
          </div>

          <div className='col-md-12 mt-3'>
            <Commonbtn Commonbtntext='Check Balance' onclicktext={handleSubmit} />
          </div>

          {errorMessage && (
            <div className='col-md-12'>
              <p className='text-danger'>{errorMessage}</p>
            </div>
          )}

          {balanceDetails && (
            <div className='col-md-12 mt-3'>
              <ResultValue balance={balanceDetails.balance} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Balance;
