import React, { useState } from 'react';
import Logo from '../../Logo/Logo';
import './Setpassword.css';
import Commonbtn from '../../Commonbtn/Commonbtn';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Password from '../../Input/Password';

function Setpassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const mobile = location.state?.mobile || '';

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        // Validate passwords
        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            setLoading(false);
            return;
        }
        const baseUrl = `${process.env.REACT_APP_BASE_API_URL}user/password/reset`;

        try {
            const response = await fetch(baseUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({ mobile, password: password }),
            });

            const result = await response.json();

            if (response.ok) {
                // Navigate to login page after successful password reset
                navigate('/login');
            } else {
                setError(result.message || 'Failed to reset password. Please try again.');
            }
        } catch (error) {
            setError('An error occurred. Please try again.');
        }

        setLoading(false);
    };

    return (
        <section className='authentication otp card'>
            <div className=''>
                <Logo />
                <h1 className='f-40 mb-3 mt-2'>
                    Set a new password
                </h1>
                <p className='f-18 mb-4 greyf6'>
                    Create a new password. Ensure it differs from previous ones for security.
                </p>

                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Password
                                placeholdertext1="Enter your Password"
                                labeltext1="Password"
                                fortext1="Password"
                                typetext1="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className='col-md-12'>
                            <Password
                                placeholdertext1="Re-enter password"
                                labeltext1="Confirm Password"
                                fortext1="Password"
                                typetext1="Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </div>
                    </div>
                    {error && <p className='error-message'>{error}</p>}
                    <div className="col-md-12 d-flex-center btn-form">
                        <Commonbtn Commonbtntext={loading ? 'Setting Password...' : 'Set Password'} disabled={loading} />
                    </div>
                </form>

                <p className='f-16 greyf6 text-center mt-3'>
                    Back to
                    <Link to='/login'>
                        <strong> Login</strong>
                    </Link>
                </p>
            </div>
        </section>
    );
}

export default Setpassword;
