import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import gifziplusion from "../Assets/gifziplusion.svg";
import './MyCard.css';

function Mycard() {
  const [cards, setCards] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCards = async () => {
      const baseUrl = `${process.env.REACT_APP_BASE_API_URL}gifzi_plus/all`;
      const token = localStorage.getItem("authToken");

      try {
        const response = await fetch(baseUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
        });

        if (response.ok) {
          const data = await response.json();
          if (data && data.data && data.data.cards) {
            setCards(data.data.cards);
          } else {
            setErrorMessage('No cards found.');
          }
        } else {
          setErrorMessage('');
        }
      } catch (error) {
        setErrorMessage('Error fetching cards. Please try again later.');
      }
    };

    fetchCards();
  }, []);

  return (
    <div className='card-list'>
      <div className='container p-60'>
        <h3 className="title">Gifzi Plus Card</h3>
        <div className='row'>
          {errorMessage && (
            <div className="col-md-12">
              <p className='text-danger'>{errorMessage}</p>
            </div>
          )}

          {cards.length > 0 ? (
            cards.map((card, index) => (
              <div key={index} className='col-md-3'>
                <div className='plus-item card p-3'>
                {/* <div className='plus-item card p-3' style={{ backgroundColor: card.card_color }}> */}
                  <i className="mr-3 prtimg">
                    <img
                      src={gifziplusion}
                      alt="product"
                      height="100%"
                      width="100%"
                    />
                  </i>
                  <p className='account-number f-20 mb-1 f-weight-600'>
                    {card.card_number}
                  </p>
                  <p className='text-danger f-16 mb-3 f-weight-600'>
                    Expiry: {card.expiry}
                  </p>
                  {/* Hidden input for card ID */}
                  <input type="hidden" value={card.id} />

                  <p className='text-main f-16 text-decoration-underline cursor-pointer' onClick={() => {
                    navigate('/balance', { 
                      state: { 
                        cardNumber: card.card_number, 
                        cardId: card.id // Pass the card ID along with the card number
                      } 
                    });
                  }}>
                    Check Balance
                  </p>
                  {/* <p className='text-success f-16 text-decoration-underline cursor-pointer'  onClick={() => {
                    navigate('/rechargegifziplus', { 
                      state: { 
                        cardNumber: card.card_number, 
                        cardId: card.id // Pass the card ID along with the card number
                      } 
                    });
                  }}>
                   Recharge Now
                  </p> */}
                </div>
              </div>
            ))
          ) : (
            <div className="col-md-12 d-flex-center f-weight-600 mt-5 mb-5">
              <p className='f-16 mb-5 mt-5 pb-5'>No cards available.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Mycard;
