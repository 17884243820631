import React from 'react'
import hm from '../Assets/Icon/hm.png'
import './Offers.css'
import Strip from './Strip'

const offerdata = [
    {
        image: hm,
        title: 'Shop in Style!',
        description: 'Get heavy discount on the H&M app',
        terms: '*T&C apply'
    },
    {
        image: hm,
        title: 'Shop in Style!',
        description: 'Get heavy discount on the H&M app',
        terms: '*T&C apply'
    },
    {
        image: hm,
        title: 'Shop in Style!',
        description: 'Get heavy discount on the H&M app',
        terms: '*T&C apply'
    },
    {
        image: hm,
        title: 'Shop in Style!',
        description: 'Get heavy discount on the H&M app',
        terms: '*T&C apply'
    },
    {
        image: hm,
        title: 'Shop in Style!',
        description: 'Get heavy discount on the H&M app',
        terms: '*T&C apply'
    },
    {
        image: hm,
        title: 'Shop in Style!',
        description: 'Get heavy discount on the H&M app',
        terms: '*T&C apply'
    },
]
function Offers() {
  return (
    <div className='offers p-60'>   
   <div className='container'>
    <h1 className='f-40 text-center mb-3'>Offers</h1>
    <p className='f-20 grey text-center'>Special offers handpicked for you</p>
    <div className='row offer-item'>
    {
        offerdata.map((item) => (
            <div key={item.title} className='col-md-3'>
                <div className='offer-card shadow-sm rounded-3 bg-white'>
                    <img src={item.image} alt={item.title} height="30px" width="30px" className='mb-1' />
                    <h3 className='f-20 mb-1' >{item.title}</h3>
                    <p className='f-18 text-muted mb-1'>{item.description}</p>
                    <p className='f-12 text-muted'>{item.terms}</p>
                    <button>Buy Now</button>
                </div>
            </div>
        ))
 
    }
 

   </div>
   <Strip/>
   </div>
  </div>
  )
}

export default Offers