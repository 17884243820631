import React from 'react';
import Commonbtn from '../Commonbtn/Commonbtn';
import './Category.css';
import catImg from "../AmazonImg/Kalyan.jpg";
import { Link } from 'react-router-dom';

function Category() {
    const [category, setCategory] = React.useState([]);

    const fetchProductData = async() => {
        const baseUrl = `${process.env.REACT_APP_BASE_API_URL}home/homeData`;

        try{
            const fetchData = await fetch(baseUrl, {
                headers:{
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            });

            const jsonData = await fetchData.json();

            if (jsonData && jsonData.status === 200) {
                const productJson = jsonData.data?.categories;
                console.log(productJson);
                setCategory(productJson);
            }
        } catch(err) {
            console.log(err);
        }
    }

    React.useEffect(() => {
        fetchProductData();
    }, []);

    return (
        <div className='Category-list p-80 text-center bg-fb'>
            <div className='container'>
                <h3 className='title'>Categories</h3>
                <div className='row'>
                    {Array.isArray(category) && category.map((item, index) => (
                        <div key={index} className='col-md-4 gift-card' data-aos="flip-right" data-aos-delay="300">
                            <Link 
                                to={{
                                    pathname: `/categoryproduct/${item.id}`,
                                    state: { categoryName: item?.name }
                                }}
                                className='card d-block p-3 mb-4 border-0'
                            >
                                   <h3 className='f-20 f-weight-600 mb-2'>
                                    {item?.name}
                                </h3>
                                <i className='product-img'>
                                    <img src={catImg} alt='category' height="100%" width="100%" />
                                </i>
                             
                            </Link>
                        </div>
                    ))}

                    <div className='col-md-12'>
                        <Link to='/Viewall'>
                            <Commonbtn Commonbtntext="SEE ALL" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Category;
