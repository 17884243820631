import React, { useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import closeIcon from '../Assets/Icon/close.svg';
import order from '../Assets/Icon/order.svg';
import ord from '../Assets/Icon/ord.svg';
import earn from '../Assets/Icon/earn.svg';
import offer from '../Assets/Icon/offer.svg';
import help from '../Assets/Icon/helpoutline.svg';
import logoutIcon from '../Assets/logout.png'; // Renamed to avoid conflict with 'logout' function
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

function Sidebar({ isOpen, toggleDropdown }) {
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  
  const token = localStorage.getItem('authToken');
  const isLoggedIn = !!token; // Convert token to boolean
  const firstName = localStorage.getItem('firstName') || 'User'; // Default to 'User' if no first name
  
  // Handle outside click to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        toggleDropdown(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, toggleDropdown]);

  // Handle item click to close the sidebar
  const handleItemClick = () => {
    toggleDropdown(false);
  };

  // Handle user logout
  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('firstName');
    toggleDropdown(false);
    navigate('/login');
  };

  return (
    <ul className={`menu-dropdown shadow-sm ${isOpen ? 'open' : ''}`} ref={dropdownRef}>
      <li className='closeicon' onClick={() => toggleDropdown(false)}>
        <img src={closeIcon} alt="close" height="30px" width="30px" className='closeiconimg' />
      </li>

      {/* Display user profile or login option */}
      <li className='menu-dropdown-list profile-nav desk-none' onClick={handleItemClick}>
        {isLoggedIn ? (
          <p className='outline-btn d-flex-center gap-2'>
            <FontAwesomeIcon icon={faUser} className='f-15' />
            <span className='f-14'>{firstName}</span>
          </p>
        ) : (
          <Link to="/login">
            <p className='outline-btn d-flex-center gap-2'>
              <FontAwesomeIcon icon={faUser} className='f-15' />
              <span className='f-14'>Login</span>
            </p>
          </Link>
        )}
      </li>

      <li className='menu-dropdown-list desk-none' onClick={handleItemClick}>
        <Link to="/home"><span className='text-list'>Home</span></Link>
      </li>
      <li className='menu-dropdown-list desk-none' onClick={handleItemClick}>
        <Link to="/about"><span className='text-list'>About Us</span></Link>
      </li>
      <li className='menu-dropdown-list desk-none' onClick={handleItemClick}>
        <Link to="/gift-cards"><span className='text-list'>Gift Cards</span></Link>
      </li>
      <li className='menu-dropdown-list desk-none' onClick={handleItemClick}>
        <Link to="/giftplus"><span className='text-list'>Gifzi Plus</span></Link>
      </li>
      <li className='menu-dropdown-list desk-none' onClick={handleItemClick}>
        <Link to="/HowItWorks"><span className='text-list'>How It Works</span></Link>
      </li>

      {/* Display additional options if the user is logged in */}
      {isLoggedIn && (
        <>
          <li className='menu-dropdown-list' onClick={handleItemClick}>
            <Link to="/Order">
              <img src={ord} className='menu-list-icon' alt='Order' height="60" width="100px" />
              <span className='text-list'>My Order</span>
            </Link>
          </li>
          <li className='menu-dropdown-list' onClick={handleItemClick}>
            <Link to="/mycard">
              <img src={order} className='menu-list-icon' alt='Order' height="60" width="100px" />
              <span className='text-list'>My Card</span>
            </Link>
          </li>
          <li className='menu-dropdown-list' onClick={handleItemClick}>
            <Link to="/write-earn">
              <img src={earn} className='menu-list-icon' alt='Earn' height="60" width="100px" />
              <span className='text-list'>Write & Earn</span>
            </Link>
          </li>
        </>
      )}

      <li className='menu-dropdown-list' onClick={handleItemClick}>
        <Link to="/offer">
          <img src={offer} className='menu-list-icon' alt='Offers' height="60" width="100px" />
          <span className='text-list'>Offers</span>
        </Link>
      </li>
      <li className='menu-dropdown-list' onClick={handleItemClick}>
        <Link to="/faq">
          <img src={help} className='menu-list-icon' alt='Help' height="60" width="100px" />
          <span className='text-list'>Help</span>
        </Link>
      </li>

      {/* Logout option, displayed only if the user is logged in */}
      {isLoggedIn && (
        <li className='menu-dropdown-list desk-none' onClick={handleLogout}>
          <img src={logoutIcon} className='menu-list-icon' alt='Logout' height="60" width="100px" />
          <span className='text-list'>Log out</span>
        </li>
      )}
    </ul>
  );
}

export default Sidebar;
