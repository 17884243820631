import React, { useState } from 'react';
import Logo from '../../Logo/Logo';
import './../Form.css';
import './Otp.css';
import Commonbtn from '../../Commonbtn/Commonbtn';
import { useLocation, Link, useNavigate } from 'react-router-dom';

function ForgetpasswordOtp() {
    const [otp, setOtp] = useState(Array(6).fill(''));
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [resendLoading, setResendLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    // Get mobile number from registration form via location.state
    const mobile = location.state?.mobile || '+91 - 8888888888';

    // Handle change for each OTP input field
    const handleChange = (element, index) => {
        if (/^[0-9]$/.test(element.value) || element.value === "") {
            const newOtp = [...otp];
            newOtp[index] = element.value;
            setOtp(newOtp);

            // Move to the next input field after entering a number
            if (element.value && element.nextSibling) {
                element.nextSibling.focus();
            }
        }
    };

    // Handle backspace key press
    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace') {
            const newOtp = [...otp];
            newOtp[index] = '';
            setOtp(newOtp);
            if (index > 0) {
                // Move focus to the previous input field
                const previousInput = document.getElementById(`otp-input-${index - 1}`);
                previousInput.focus();
            }
        }
    };

    // Handle OTP form submission
    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        setError(null);

        const otpCode = otp.join('');
        if (otpCode.length !== 6) {
            setError("Please enter a valid 6-digit OTP.");
            return;
        }

        setLoading(true);
        const baseUrl = `${process.env.REACT_APP_BASE_API_URL}user/register/verifyOTP`;

        try {
            const response = await fetch(baseUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    type: 'mobile',
                    mobile: mobile,
                    otp: otpCode,
                }),
            });

            const result = await response.json();

            if (response.ok) {
                navigate('/newpassword', { state: { mobile: '8619807171' } });
            } else {
                setError(result.message || 'OTP verification failed');
            }
        } catch (err) {
            setError('Something went wrong, please try again.');
        }
        setLoading(false);
    };

    // Handle Resend OTP
    const handleResendOtp = async () => {
        setResendLoading(true);
        setError(null);
        const baseUrl = `${process.env.REACT_APP_BASE_API_URL}user/login/resend`;

        try {
            const response = await fetch(baseUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ mobile }),
            });

            const result = await response.json();

            if (response.ok) {
                console.log("OTP resent successfully");
            } else {
                setError(result.message || 'Failed to resend OTP');
            }
        } catch (err) {
            setError('Something went wrong, please try again.');
        }
        setResendLoading(false);
    };

    return (
        <section className='authentication otp card'>
            <div className=''>
                <Logo className="logo" />
                <h1 className='f-40 mb-2'>OTP Verification</h1>
                <p className='f-18 mb-4 greyf6'>
                    We sent you a one-time OTP on this Mobile Number <strong>{mobile}</strong>
                </p>

                <label className='f-16 f-weight-600'>Enter your OTP</label>
                <div className='inputs-5 d-flex'>
                    {otp.map((digit, index) => (
                        <input
                            key={index}
                            id={`otp-input-${index}`} // Assign an ID for reference
                            type="tel"
                            maxLength="1"
                            className='form-control form-control-lg input-otp'
                            value={otp[index]}
                            onChange={e => handleChange(e.target, index)}
                            onFocus={e => e.target.select()}
                            onKeyDown={e => handleKeyDown(e, index)} // Handle backspace key
                        />
                    ))}
                </div>

                {error && <p className='error-message'>{error}</p>}

                <p className='f-18 text-center mb-4'>
                    Did not receive OTP? 
                    <strong className='text-decoration-underline cursor-pointer' onClick={handleResendOtp}>
                        {resendLoading ? 'Resending...' : ' Resend'}
                    </strong>
                </p>

                <form onSubmit={handleVerifyOtp}>
                    <div className="col-md-12 d-flex-center btn-form">
                        <Commonbtn Commonbtntext={loading ? 'Verifying...' : 'Verify code'} disabled={loading} />
                    </div>
                </form>

                <p className='f-16 greyf6 text-center mt-3'>
                    Back to <Link to='/login'><strong>Login</strong></Link>
                </p>
            </div>
        </section>
    );
}

export default ForgetpasswordOtp;
