import React, { useEffect } from 'react';
import './Successfull_Page.css';
import { Link } from 'react-router-dom';

const Successfull_Page = () => {
  const baseUrl = process.env.REACT_APP_BASE_API_URL; // Use an environment variable for the base URL
  const params = new URLSearchParams(window.location.search);
  const transactionId = params.get("txnid") || "579238562378587"; // Get transaction ID from URL or use a default
  const amount = params.get("amount") || 100; // Get amount from URL or use a default
  const status = params.get("status") || "success"; // Get status from URL

  useEffect(() => {
    // const postCheckoutData = async () => {
    //   const apiUrl = `${baseUrl}order/checkout`; // Construct the complete API URL

    //   const payload = {
    //     payment_mode: 1, // Online payment mode
    //     status: status === "success" ? 1 : 0, // Send status as 1 for success, 0 for failure
    //     is_gift: 0, // Example value, set as per your requirement
    //     online_payment_detail: {
    //       transaction_id: transactionId,
    //       amount: amount,
    //     },
    //   };

    //   try {
    //     const response = await fetch(apiUrl, {
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/json',
    //         Authorization: `${localStorage.getItem('authToken')}`, // Make sure to send token in correct format
    //       },
    //       body: JSON.stringify(payload),
    //     });

    //     if (response.ok) {
    //       const data = await response.json();
    //       console.log('Checkout successful:', data);
    //     } else {
    //       const errorData = await response.json();
    //       console.error('Error during checkout:', errorData.message);
    //     }
    //   } catch (error) {
    //     console.error('API call error:', error);
    //   }
    // };

    //postCheckoutData(); // Call the function on component load
  }, [baseUrl, transactionId, amount, status]);

  return (
    <div className='successfullpage'>
      <div className="js-container container">
        <div className="celebration-content">
          <div className="checkmark-circle">
            <div className="background"></div>
            <div className="checkmark draw"></div>
          </div>
          <h1>Congratulations!</h1>
          <p>You are all set. Well done!</p>
          <Link to="/gift-cards">
          <button className="common-btn" >
            Back
          </button>
          </Link>
        </div>

        {/* <h1 className='text-sucess f-30 mt-3'>Congratulations!</h1>
        {transactionId}
        {status}
        <p className='f-16 text-grey mt-3 mb-3'>You are all set. Well done!</p>
        <button className="submit-btn">Continue</button> */}
      </div>
    </div>
  );
};

export default Successfull_Page;
